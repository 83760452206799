<template>
    <div class="flex p-3">
        <div
            v-if="userCan('read clients')"
            class="bg-gray-200 py-1 px-4 mr-3 rounded-full text-sm cursor-pointer"
            :class="clientsSelection && 'bg-blue-400 text-white'"
            @click="selectionChange('clients')"
        >
            {{ $t('search.clients') }}
        </div>
        <div
            v-if="userCan('read employees')"
            class="bg-gray-200 py-1 px-4 mr-3 rounded-full text-sm cursor-pointer"
            :class="employeesSelection && 'bg-blue-400 text-white'"
            @click="selectionChange('employees')"
        >
            {{ $t('search.employees') }}
        </div>
        <div
            v-if="userCan('read keys')"
            class="bg-gray-200 py-1 px-4 rounded-full text-sm cursor-pointer"
            :class="keysSelection && 'bg-blue-400 text-white'"
            @click="selectionChange('keys')"
        >
            {{ $t('search.keys') }}
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            clientsSelection:   window.Cookies.get('turbo_search_clients_selection') !== 'false',
            employeesSelection: window.Cookies.get('turbo_search_employees_selection') !== 'false',
            keysSelection:      window.Cookies.get('turbo_search_keys_selection') !== 'false',
        };
    },

    created() {
        this.$emit('setInitialSearchSelection', {
            clients:   this.clientsSelection,
            employees: this.employeesSelection,
            keys:      this.keysSelection,
        });
    },

    methods: {
        selectionChange(type) {
            if (this.checkIfUserWantToDeselectAllOptions(type)) return;

            this[`${type}Selection`] = !this[`${type}Selection`];
            window.Cookies.set(`turbo_search_${type}_selection`, this[`${type}Selection`], { expires: 365 });

            this.$emit('selectionChange', {
                type,
                value: this[`${type}Selection`],
            });
        },

        checkIfUserWantToDeselectAllOptions(type) {
            const selection = {
                clients:   this.clientsSelection,
                employees: this.employeesSelection,
                keys:      this.keysSelection,
            };

            selection[type] = !this[`${type}Selection`];

            if (!selection.clients && !selection.employees && !selection.keys) {
                return true;
            }

            return false;
        },
    },
};
</script>
